@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* hero component */

/* .bg_gradient {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.42),
      rgba(238, 238, 238, 1)
    ),
    url("../src/assets/images/banner_bg.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  max-height: 753px;
  min-height: 536px;
} */

.loader {
  width: 26px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        rgb(0 0 0/50%) 30%,
        #0000 0 70%,
        rgb(0 0 0/100%) 0
      )
      50%/8% 100%,
    linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0)
      50%/100% 8%;
  background-repeat: no-repeat;
  animation: loader 1s infinite steps(12);
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes loader {
  100% {
    transform: rotate(1turn);
  }
}
/* loader white */
.loaderWhite {
  width: 26px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: linear-gradient(0deg, #ffffff80 30%, #ffffff00 0 70%, #ffffff 0)
      50%/8% 100%,
    linear-gradient(90deg, #ffffff40 30%, #ffffff00 0 70%, #ffffffbf 0) 50%/100%
      8%;
  background-repeat: no-repeat;
  animation: loaderWhite 1s infinite steps(12);
}

.loaderWhite::before,
.loaderWhite::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loaderWhite::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes loaderWhite {
  100% {
    transform: rotate(1turn);
  }
}

.bg_gradient {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.42),
      rgb(94, 92, 92) /* Slightly darker white */
    ),
    url("../src/assets/images/banner_bg.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  max-height: 100vh;
  min-height: 100vh;
  /* animation */
  animation: bgMove 60s infinite alternate ease-in-out;
}

/* stop animation on mobile screen */
@media (max-width: 500px) {
  .bg_gradient {
    animation: none;
  }
}
/* banner bg img animation */
@keyframes bgMove {
  0% {
    background-position: top center;
  }
  100% {
    background-position: bottom center;
  }
}

@media (min-width: 300px) {
  .bannerPadding {
    padding-top: 50px;
  }
}
@media (min-width: 500px) {
  .bannerPadding {
    padding-top: 170px;
  }
}
@media (min-width: 1000px) {
  .bannerPadding {
    padding-top: 110px;
  }
}
@media (min-width: 1500px) {
  .bannerPadding {
    padding-top: 100px;
  }
}
@media (min-width: 1800px) {
  .bannerPadding {
    padding-top: 200px;
  }
}

.bg_gradient_mobile {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.42),
      rgba(238, 238, 238, 1)
    ),
    url("../src/assets/images/bg_hero_section_mobile.svg");
  background-repeat: no-repeat;
  background-position: top center;
  /* background-size: 100%; */
  max-height: 536px;
}

.bg_xx_image {
  background-image: url("../src/assets/images/xx_group.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 436.84px 327px;
}

.bg_join_mixxer {
  background-image: url("../src/assets/images/bg_brown_mixxer.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg_social_web_screen {
  background-image: url("../src/assets/images/xx_group.svg");
  background-repeat: no-repeat;
  /* background-position: right; */
  /* background-size: auto 82%; */
  background-position: -20px 75%;
  background-size: 595px 445.5px;
}

.bg_essentials {
  background-image: url("../src/assets/images/essentials_phone.svg");
  background-repeat: no-repeat;
  /* background-position: center; */
  background-position: 45% 80%;
  background-size: 637px 546px;
}

.bg_essentials_xx_image {
  /* background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.42),
    rgba(238, 238, 238, 1)
  ),
  url("../src/assets/images/xx_group.svg"); */
  background-image: url("../src/assets/images/xx_group.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 637px 546px;
}

.bg_xx_image_mobile {
  background-image: url("../src/assets/images/bg_xx_image_mobile.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
}
